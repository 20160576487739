import { colours } from './colours';

const MapLegend = () => {
    const gradientStyle = {
        opacity: 0.5,
        background: `linear-gradient(90deg, ${colours.WHITE} 0%, ${colours.GREY} 10% , ${colours.BLUE} 100%)`
    }

    return <div id='legend-container'>
        <p className='legend-text'>0</p>
        <span id='legend-gradient' style={gradientStyle} />
        <p className='legend-text'>10+</p>
    </div>
}

export default MapLegend