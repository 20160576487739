export const getOutlets = async () => {
    const response = await fetch("https://sheets.googleapis.com/v4/spreadsheets/1JmQlgtLaSXpdZ0lhoszsu-QHFgi6jJYjS5Fl5LKH6IQ/values/Outlets_map?key=AIzaSyAoCY0kF37fN2CFBK3YilNFoYKTs3GV16Y");
    const { values } = await response.json();

    const outlets = [];

    values.forEach((row, index) => {
        if (index > 0) {
            const outlet = {};
            row.forEach((item, i) => {
                outlet[values[0][i]] = item;
            })

            if (outlet.LOCATION)
                outlet.LOCATION = outlet.LOCATION.split(",");

            if (outlet.OWNER_LOCATION)
                outlet.OWNER_LOCATION = outlet.OWNER_LOCATION.split(",");

            outlet.INDEPENDENCE = outlet.INDEPENDENCE === "TRUE";

            outlet.ID = outlet.TITLE_ID;
            outlet.COVERAGE_LAD = outlet.COVERAGE_LAD_2023;

            if (outlet.ID !== values[index - 1][0] && outlet.LOCATION)
                outlets.push(outlet);
        }
    });

    return outlets;
}