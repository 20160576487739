import { useState, useRef, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
//import outlets from './data/UK-local-news-list';
import { getOutlets } from './data/sheets';
import Map, { NavigationControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import SearchBox from './SearchBox';
import Filter from './Filter';
import OutletCard from './OutletCard';
import OutletMarkers from './OutletMarkers';
import LADs from './Boundaries';
import LADLabel from './LADLabel';
import MapLegend from './MapLegend';
import arrow from './arrow-back.svg';
import fbukg from './fbukg.svg';

function App() {
  const mapRef = useRef();
  const outletRefs = useRef({});
  const [outlets, setOutlets] = useState([]);
  const [mapBounds, setMapBounds] = useState();
  const [hoverLAD, setHoverLAD] = useState();
  const [hoverOutlet, setHoverOutlet] = useState();
  const [selectedOutlet, setSelectedOutlet] = useState();
  const [activeLAD, setActiveLAD] = useState();
  const [selectedMedium, setSelectedMedium] = useState();
  const [selectedType, setSelectedType] = useState();
  const [clusterActive, setClusterActive] = useState(null);

  const visibleOutlets = outlets.filter(outlet =>
    (!activeLAD || outlet.COVERAGE_LAD.includes(activeLAD.LAD23CD)) &&
    (!selectedMedium || outlet.MEDIA_TYPE.toLowerCase() === selectedMedium.toLowerCase() || (outlet.MEDIA_TYPE === "BBC Local Radio" && selectedMedium === "Community Radio")) && //toLowerCase() because some are listed as "Print and Online" vs "Print and online"
    (!selectedType || outlet.INDEPENDENCE === (selectedType === "Independent"))
  );

  const outletCards = visibleOutlets.map(outlet =>
    <OutletCard
      innerRef={(element) => outletRefs.current[outlet.ID] = element}
      outlet={outlet}
      hoverOutlet={hoverOutlet}
      setHoverOutlet={setHoverOutlet}
      selectedOutlet={selectedOutlet}
      setSelectedOutlet={setSelectedOutlet}
      key={outlet.ID}
    />);

  useEffect(() => {
    getOutlets().then(outlets => setOutlets(outlets));
  }, [])

  useEffect(() => {
    if (selectedOutlet) {
      const outletCard = outletRefs.current[selectedOutlet.ID]
      outletCard.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }

  }, [selectedOutlet])


  const LADHover = (event) => {
    if (event.features[0]) {
      const LAD = event.features[0].properties;
      if (!hoverLAD || hoverLAD.LAD23NM !== LAD.LAD23NM)
        setHoverLAD(LAD);
    }
    else
      if (hoverLAD !== null)
        setHoverLAD(null);
  }
  useEffect(() => {
    if (selectedOutlet && !visibleOutlets.includes(selectedOutlet)) {
      setSelectedOutlet(null);
    }
  }, [activeLAD, visibleOutlets, selectedOutlet])

  const goToLAD = (LAD) => {
    setSelectedOutlet(null);
    if (LAD) {
      setActiveLAD(LAD);
      mapRef.current.fitBounds(
        [
          [LAD.minLng, LAD.minLat],
          [LAD.maxLng, LAD.maxLat]
        ],
        { padding: 40, duration: 1000 }
      );
    }
    else {
      setActiveLAD(null);
      mapRef.current.easeTo({ center: [-4, 55.7], zoom: 5 });
    }
  }

  return (
    <div className="App">
      <div id="sidebar">
        <header>
          <a href="https://publicinterestnews.org.uk/map">
            <img src={logo} className="App-logo" alt="logo" />
          </a>
          <a href="https://publicinterestnews.org.uk/map">
            <p>Local <br /> News Map</p>
          </a>
        </header>
        <div id="scrollable-area">
          <p id="description-text">
            This map (in beta) shows UK local news coverage and outlets. Read more <a href="https://publicinterestnews.org.uk/map">about the map</a> and <a href="https://forms.office.com/e/kyDBYN2Uwy">provide feedback</a>. Part-funded by UK Government.
          </p>
          <span className='white-line' />
          <SearchBox goToLAD={goToLAD} />
          <section id='section-filter'>
            <span className='white-line second' />
            <Filter
              selectedMedium={selectedMedium}
              setSelectedMedium={setSelectedMedium}
              selectedType={selectedType}
              setSelectedType={setSelectedType}
            />
          </section>
          <h1>
            {activeLAD && <img className='arrow' src={arrow} alt="back to all" onClick={() => goToLAD(null)} />}
            {activeLAD ? activeLAD.LAD23NM : "All"}
          </h1>
          <section id="outlet-list-container">
            {outletCards}
            <img id='fbukg' src={fbukg} alt="Funded by UK Government" />
          </section>
        </div>
      </div>
      <div id="map-container">
        <Map
          ref={mapRef}
          minZoom={5}
          initialViewState={{
            longitude: -4,
            latitude: 55.7,
            zoom: 5
          }}
          style={{ width: '100%', height: '100%', borderRadius: "1rem", position: 'relative' }}
          mapStyle="mapbox://styles/kingmob/cltyll6m600hy01qsh9kk19ky"
          mapboxAccessToken={"pk.eyJ1IjoicGluZiIsImEiOiJjbGx6YXZ0a2kxM2YxM2VsZjdrcHl2eWFxIn0.9FVkiI5Vj94qnNWof18gpg"}
          interactiveLayerIds={['data']}
          onClick={event => {
            !event.originalEvent.defaultPrevented && event.features[0] && goToLAD(event.features[0].properties);
          }}
          onMove={event => {
            setMapBounds(mapRef.current.getBounds());
            if (!clusterActive || !clusterActive.find(outlet => outlet === selectedOutlet))
              setClusterActive(null);
          }}
          onMouseMove={LADHover}
        >
          {outlets.length > 0 && <LADs activeLAD={activeLAD} outlets={outlets} />}
          {hoverLAD && !activeLAD && <LADLabel hoverLAD={hoverLAD} outlets={outlets} />}
          {activeLAD && <OutletMarkers
            outlets={visibleOutlets}
            hoverOutlet={hoverOutlet}
            setHoverOutlet={setHoverOutlet}
            activeLAD={activeLAD}
            selectedOutlet={selectedOutlet}
            setSelectedOutlet={setSelectedOutlet}
            goToLAD={goToLAD}
            mapBounds={mapBounds}
            clusterActive={clusterActive}
            setClusterActive={setClusterActive}
          />}
          <NavigationControl />
          <MapLegend />
        </Map>

      </div>
    </div >
  );
}

export default App;
