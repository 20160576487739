import { Marker } from 'react-map-gl';

const LADLabel = ({ hoverLAD, outlets }) => {
    const outletsInLAD = outlets.filter(outlet => outlet.COVERAGE_LAD.includes(hoverLAD.LAD23CD));

    return <Marker
        style={{ zIndex: 0 }} longitude={hoverLAD.LONG} latitude={hoverLAD.LAT}
    >
        <div className='location-label'>
            <p className='location-label-text'>{hoverLAD.LAD23NM}</p>
            <p className='location-label-text'>Titles: {outletsInLAD.length}</p>
        </div>
    </Marker>
}

export default LADLabel