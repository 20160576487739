import { useState } from 'react';
import chevron from "./chevron-down.svg";
import plus from './plus.svg';
import minus from './minus.svg';
import online from './online-icon.svg';
import printAndOnline from './print-and-online-icon.svg';
import radio from './radio-icon.svg';
import tv from './tv-icon.svg';

const independentIcon = <span className='indy-pill'>Independent</span>;
const corporateIcon = <span className='corp-pill'>Corporate</span>

const mediumIcons = {
    "Online": <img src={online} alt="online-icon" className='filter-option-icon' />,
    "Print and Online": <img src={printAndOnline} alt="print-and-online-icon" className='filter-option-icon' />,
    "Community Radio": <img src={radio} alt="radio-icon" className='filter-option-icon' />,
    "Local TV Station": <img src={tv} alt="tv-icon" className='filter-option-icon' />
}

const Filter = ({ selectedMedium, setSelectedMedium, selectedType, setSelectedType }) => {
    const [filterOpen, setFilterOpen] = useState(false);
    const [mediumOpen, setMediumOpen] = useState(false);
    const [typeOpen, setTypeOpen] = useState(false);

    return <div id="subtitle-bar">
        {!filterOpen && <p>Local News Outlets </p>}
        <div className={`filter ${filterOpen ? "filter-open" : "filter-closed"}`}>
            <span id="filter-toggle" onClick={() => setFilterOpen(!filterOpen)}>
                <p>Filter</p>
                {filterOpen ?
                    <img className='filter-icon' src={minus} alt="filter-closed-icon" /> :
                    <img className='filter-icon' src={plus} alt="filter-open-icon" />}
            </span>
            {filterOpen && <div id="select-container">
                <div className='select-options'>
                    <div className="select-title" onClick={() => setMediumOpen(!mediumOpen)}>
                        <p>Select Medium</p>
                        <img src={chevron} className={`chevron ${mediumOpen && "rotated"}`} alt="chevron" />
                    </div>
                    {mediumOpen ? <div>
                        <span onClick={() => setSelectedMedium(null)} className='filter-option'><p>All {!selectedMedium && "✓"}</p></span>
                        <span onClick={() => setSelectedMedium("Online")} className='filter-option'>
                            {mediumIcons["Online"]}
                            <p>Online {selectedMedium === "Online" && "✓"}</p>
                        </span>
                        <span onClick={() => setSelectedMedium("Print and Online")} className='filter-option'>
                            {mediumIcons["Print and Online"]}
                            <p>Print & Online {selectedMedium === "Print and Online" && "✓"}</p>
                        </span>
                        <span onClick={() => setSelectedMedium("Community Radio")} className='filter-option'>
                            {mediumIcons["Community Radio"]}
                            <p>Radio {selectedMedium === "Community Radio" && "✓"}</p>
                        </span>
                        <span onClick={() => setSelectedMedium("Local TV Station")} className='filter-option'>
                            {mediumIcons["Local TV Station"]}
                            <p>TV {selectedMedium === "Local TV Station" && "✓"}</p>
                        </span>
                    </div> :
                        <span className='filter-option'>{mediumIcons[selectedMedium]}<p>{selectedMedium || "All"} ✓</p></span>
                    }
                </div>

                <div className='select-options'>
                    <div className="select-title" onClick={() => setTypeOpen(!typeOpen)}>
                        <p>Select Outlet type</p>
                        <img src={chevron} className={`chevron ${typeOpen && "rotated"}`} alt="chevron" />
                    </div>
                    {typeOpen ?
                        <div>
                            <p onClick={() => setSelectedType(null)}>{independentIcon} & {corporateIcon} {!selectedType && "✓"}</p>
                            <p onClick={() => setSelectedType("Independent")}>{independentIcon} Only {selectedType === "Independent" && "✓"}</p>
                            <p onClick={() => setSelectedType("Corporate")}>{corporateIcon} Only {selectedType === "Corporate" && "✓"}</p>
                        </div>
                        :
                        selectedType ?
                            <p>{selectedType === "Independent" ? independentIcon : corporateIcon} ✓</p> :
                            <p>{independentIcon} & {corporateIcon} ✓</p>
                    }
                </div>

            </div>}
        </div>
    </div>
}

export default Filter;