import link from './link-arrow.svg';

const OutletCard = ({ outlet, hoverOutlet, setHoverOutlet, selectedOutlet, setSelectedOutlet, innerRef }) => {
    const cardActive = (outlet === hoverOutlet || outlet === selectedOutlet);
    const independentIcon = <span className={`indy-pill ${cardActive && `card-active`}`}>Independent</span>;
    const corporateIcon = <span className={`corp-pill ${cardActive && `card-active`}`}>Corporate</span>

    const outletHover = () => {
        if (outlet !== hoverOutlet)
            setHoverOutlet(outlet);
    }

    const outletClick = () => {
        if (outlet !== selectedOutlet)
            setSelectedOutlet(outlet)
        else
            setSelectedOutlet(null);
    }

    return <div
        className={`outlet-card ${outlet.INDEPENDENCE ? "independent" : "corporate"} ${cardActive && "card-active"}`}
        onMouseOver={outletHover}
        onMouseLeave={() => setHoverOutlet(null)}
        onClick={outletClick}
        ref={innerRef}
    >
        <section>
            <p>{outlet.INDEPENDENCE ? independentIcon : corporateIcon}</p>
            <a className="site-link" href={outlet.URL}><p>Visit site</p><img className="link-arrow" src={link} alt="link-arrow" /></a>
        </section>
        <b><p className='outlet-title'>{outlet.TITLE}</p></b>
        <p>{outlet.REGISTERED_ADDRESS}</p>
        <section>
            <p>Owned by <br /> <b className={!outlet.INDEPENDENCE ? "corp-owner" : ""}>{outlet.OWNER}</b></p>
            <aside>
                {outlet.MEDIA_TYPE && <p className='blue-pill'>{outlet.MEDIA_TYPE}</p>}
                {outlet.CONDUCT_CODE && <p className='blue-pill'>{outlet.CONDUCT_CODE}</p>}
            </aside>
        </section>
    </div>
}

export default OutletCard;