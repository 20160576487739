import { useState } from "react";
import Fuse from 'fuse.js';
import LADs from './data/LAD_boundaries';

const options = {
    includeScore: true,
    keys: ['properties.LAD23CD', 'properties.LAD23NM', 'properties.LAD23NMw']
}

const fuse = new Fuse(LADs.features, options);

const SearchBox = ({ goToLAD }) => {
    const [searchTerm, setSearchTerm] = useState("");

    const search = () => {
        if (searchTerm === "")
            goToLAD(null);

        fetch(`https://findthatpostcode.uk/postcodes/${searchTerm}.json`)
            .then(response => response.json())
            .then(json => {
                const searchText = json.data ? json.data.attributes.laua : searchTerm;
                const result = fuse.search(searchText);
                if (result.length > 0) {
                    goToLAD(result[0].item.properties);
                }
            })
    }

    return <section>
        <p id="search-box-label">Search</p>
        <input
            id="search-box"
            type="text"
            placeholder="enter postcode or local area district"
            onChange={event => setSearchTerm(event.target.value)}
            value={searchTerm}
            onKeyDown={event => event.key === "Enter" && search()}
        ></input>
    </section>
}

export default SearchBox;