import { OutletMarker } from './OutletMarkers';

const OutletCluster = ({ outlets, hoverOutlet, selectedOutlet, setHoverOutlet, markerClick, clusterActive, setClusterActive }) => {
    const outlet = outlets[0];

    const clickCluster = (event) => {
        event.preventDefault();
        setClusterActive(outlets);
    }

    if (clusterActive && clusterActive[0].ID === outlets[0].ID) {
        const angle = 360 / outlets.length;

        const outletPoints = outlets.map((outlet, i) => <div
            style={{
                transform: `rotate(${angle * i}deg) translate(2.5rem) rotate(-${angle * i}deg)`,
                position: 'absolute',
                zIndex: `${outlet === hoverOutlet ? 3 : outlet === selectedOutlet ? 2 : 1}`
            }}
        >
            <OutletMarker
                outlet={outlet}
                selectedOutletOutlet={selectedOutlet}
                hoverOutlet={hoverOutlet}
                selectedOutlet={selectedOutlet}
                setHoverOutlet={setHoverOutlet}
                markerClick={markerClick}
                cluster={true}
            />
        </div>)

        outletPoints.push(<div
            className={`outlet-cluster-centre ${outlet.INDEPENDENCE ? "blue" : "orange"}`}
            onClick={(event) => clickCluster(event)}
        />)

        return <div style={{ position: 'absolute', left: "-1rem", top: "-1rem" }}>
            {outletPoints}
        </div>
    }

    return <div
        className={`outlet-marker cluster ${outlet.INDEPENDENCE ? "blue" : "orange"} `}
        onClick={(event) => clickCluster(event)}
    >
        <p className='outlet-marker-number'>{outlets.length}</p>
    </div>
}

export default OutletCluster;