import { Marker } from 'react-map-gl';
import { Source, Layer } from 'react-map-gl';
import LADs from './data/LAD_boundaries';
import inside from 'point-in-polygon-hao';
import distance from '@turf/distance';
import OutletCluster from './OutletCluster';

const outletIsInsideLAD = (outlet, activeLADGeometry) => {
    if (activeLADGeometry.type === "Polygon") {
        const insidePolygon = inside(outlet.LOCATION, activeLADGeometry.coordinates);
        return insidePolygon;
    }
    else { //multi polygon
        let insidePolygon = false;
        activeLADGeometry.coordinates.forEach(polygon => {
            if (inside(outlet.LOCATION, polygon))
                insidePolygon = true;
        })
        return insidePolygon;
    }
}

const outletOffscreen = (outlet, mapBounds) => {
    const mapPolygon = [
        [mapBounds._ne.lng, mapBounds._ne.lat],
        [mapBounds._ne.lng, mapBounds._sw.lat],
        [mapBounds._sw.lng, mapBounds._sw.lat],
        [mapBounds._sw.lng, mapBounds._ne.lat],
        [mapBounds._ne.lng, mapBounds._ne.lat],
    ]
    const onScreen = inside(outlet.LOCATION, [mapPolygon]);
    return !onScreen;
}

const findNearestPoint = (outlet, activeLADGeometry) => {
    if (activeLADGeometry.type === "Polygon") {
        let smallestDistance;
        let nearestPoint;

        activeLADGeometry.coordinates[0].forEach(point => {
            const dist = distance(outlet.LOCATION, point, { units: "kilometers" })
            if (!nearestPoint || !smallestDistance || dist < smallestDistance) {
                smallestDistance = dist;
                nearestPoint = point;
            }
        })

        return nearestPoint;
    }
    else { //multi polygon
        let smallestDistance;
        let nearestPoint;
        activeLADGeometry.coordinates.forEach(polygon => polygon[0].forEach(point => {
            const dist = distance(outlet.LOCATION, point, { units: "kilometers" })
            if (!nearestPoint || !smallestDistance || dist < smallestDistance) {
                smallestDistance = dist;
                nearestPoint = point;
            }
        }))

        return nearestPoint;
    }
}

const sortBySelectedAndHover = (outletPoints) => {
    const hover = outletPoints.find(outlet => outlet.props.hover);
    const selected = outletPoints.find(outlet => outlet.props.selected);

    return {
        sortedOutletPoints: [...outletPoints, selected],
        hoverOutletPoint: hover
    };
}

export const OutletMarker = ({ outlet, hoverOutlet, selectedOutlet, setHoverOutlet, markerClick, cluster }) => {
    const activeOutlet = (outlet === hoverOutlet || outlet === selectedOutlet);
    outlet.cluster = cluster;

    return <div
        className={`outlet-marker ${outlet.INDEPENDENCE ? "blue" : "orange"} ${activeOutlet && "active"}`}
        onMouseOver={() => setHoverOutlet(outlet)}
        onMouseLeave={() => setHoverOutlet(null)}
        onClick={(event) => markerClick(outlet, event)}
    >
        <p className={`location-label outlet-tooltip ${activeOutlet && "active"}`}>{outlet.TITLE}{outlet === selectedOutlet && <span className='close-icon'>x</span>}</p>
        {(outlet === selectedOutlet && !outlet.OWNER_LOCATION) &&
            <div className={`ownership-card ${cluster ? "cluster" : ""} ${selectedOutlet.INDEPENDENT ? "blue" : "orange"}`}>
                <p className={`ownership-card-text ${selectedOutlet.INDEPENDENT ? "blue" : "orange"}`}>Owned {selectedOutlet.INDEPENDENT && "locally"} by <br /><b>{outlet.OWNER}</b></p>
            </div>}
    </div>
}

const OutletMarkers = ({ outlets, hoverOutlet, setHoverOutlet, activeLAD, selectedOutlet, setSelectedOutlet, mapBounds, clusterActive, setClusterActive }) => {
    const activeLADGeometry = LADs.features.find(LAD => LAD.properties.LAD23CD === activeLAD.LAD23CD).geometry;
    const outsideOutlets = [];

    const markerClick = (outlet, event) => {
        event.preventDefault();

        if (outlet !== selectedOutlet) {
            setSelectedOutlet(outlet);
        }
        else
            setSelectedOutlet(null)
    }

    const locationsWithOutlets = {};

    outlets.forEach(outlet => {
        if (locationsWithOutlets[outlet.LOCATION])
            locationsWithOutlets[outlet.LOCATION].push(outlet);
        else
            locationsWithOutlets[outlet.LOCATION] = [outlet];
    })

    const outletPoints = [];

    for (const location in locationsWithOutlets) {
        const outletsAtLocation = locationsWithOutlets[location];
        const isCluster = outletsAtLocation.length > 1;

        if (!isCluster) {
            const outlet = outletsAtLocation[0];

            if (!outletIsInsideLAD(outlet, activeLADGeometry) && mapBounds && outletOffscreen(outlet, mapBounds))
                outsideOutlets.push(outlet);

            outletPoints.push(
                <Marker
                    longitude={outlet.LOCATION[0]}
                    latitude={outlet.LOCATION[1]}
                    anchor={"center"}
                    selected={outlet === selectedOutlet}
                    hover={outlet === hoverOutlet}
                    title={outlet.TITLE}
                >
                    <OutletMarker
                        outlet={outlet}
                        selectedOutletOutlet={selectedOutlet}
                        hoverOutlet={hoverOutlet}
                        selectedOutlet={selectedOutlet}
                        setHoverOutlet={setHoverOutlet}
                        markerClick={markerClick}
                        cluster={false}
                    />
                </Marker >)
        }
        else {
            const outlet = outletsAtLocation[0];
            const clusterHasSelected = outletsAtLocation.includes(selectedOutlet);
            const clusterHasHover = outletsAtLocation.includes(hoverOutlet);

            outletPoints.push(
                <Marker
                    longitude={outlet.LOCATION[0]}
                    latitude={outlet.LOCATION[1]}
                    anchor={"center"}
                    selected={clusterHasSelected}
                    hover={clusterHasHover}
                >
                    <OutletCluster
                        outlets={outletsAtLocation}
                        hoverOutlet={hoverOutlet}
                        selectedOutlet={selectedOutlet}
                        setHoverOutlet={setHoverOutlet}
                        markerClick={markerClick}
                        clusterActive={clusterActive}
                        setClusterActive={setClusterActive}
                    />
                </Marker >)
        }
    }

    const { sortedOutletPoints, hoverOutletPoint } = sortBySelectedAndHover(outletPoints);

    const outsideLinesIndy = {
        "type": "FeatureCollection",
        "features": []
    }

    const outsideLinesCorp = {
        "type": "FeatureCollection",
        "features": []
    }

    outsideOutlets.forEach(outlet => {
        const line = {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    findNearestPoint(outlet, activeLADGeometry),
                    outlet.LOCATION
                ]
            }
        }
        if (outlet.INDEPENDENCE)
            outsideLinesIndy.features.push(line);
        else
            outsideLinesCorp.features.push(line);
    })

    const lineStyleIndy = {
        type: 'line',
        paint: {
            'line-color': '#265089',
            "line-width": 5,
            "line-dasharray": [2, 2]
        }
    }

    const lineStyleCorp = {
        type: 'line',
        paint: {
            'line-color': '#8e4a1c',
            "line-width": 5,
            "line-dasharray": [2, 2]
        }
    }

    return <>
        {sortedOutletPoints}
        <Source type="geojson" data={outsideLinesIndy}>
            <Layer {...lineStyleIndy} />
        </Source>
        <Source type="geojson" data={outsideLinesCorp}>
            <Layer {...lineStyleCorp} />
        </Source>
        {selectedOutlet && selectedOutlet.OWNER_LOCATION && <><Marker
            longitude={selectedOutlet.OWNER_LOCATION[0]}
            latitude={selectedOutlet.OWNER_LOCATION[1]}
            anchor={"center"}
        >
            <span className={`outlet-owner-marker ${selectedOutlet.INDEPENDENT ? "blue" : "orange"} ${selectedOutlet.cluster ? "cluster" : ""}`} />
            <div
                className={`outlet-owner-marker ${selectedOutlet.INDEPENDENT ? "blue" : "orange"} ${selectedOutlet.cluster ? "cluster" : ""}`}
            >
                <div className={`ownership-card ${selectedOutlet.cluster ? "cluster" : ""} ${selectedOutlet.INDEPENDENT ? "blue" : "orange"}`}>
                    <p className={`ownership-card-text ${selectedOutlet.INDEPENDENT ? "blue" : "orange"}`}>Owned by <br /><b>{selectedOutlet.OWNER}</b></p>
                </div>
            </div>
        </Marker >
            <Source type="geojson" data={{
                "type": "FeatureCollection",
                "features": [{
                    "type": "Feature",
                    "properties": {},
                    "geometry": {
                        "type": "LineString",
                        "coordinates": [
                            selectedOutlet.LOCATION,
                            selectedOutlet.OWNER_LOCATION
                        ]
                    }
                }]
            }}>
                <Layer type={'line'}
                    paint={
                        {
                            'line-color': selectedOutlet.INDEPENDENT ? '#265089' : '#8e4a1c',
                            "line-width": 5,
                            "line-dasharray": [2, 2]
                        }
                    }
                />
            </Source>
        </>}
        {hoverOutletPoint}
    </>
}

export default OutletMarkers;