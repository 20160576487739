import { Source, Layer } from 'react-map-gl';
import LADData from './data/LAD_boundaries';
import { colours } from './colours';

const calculateZoom = (LADs) => {
    LADs.features.forEach(LAD => {
        let minLng, minLat, maxLng, maxLat;

        if (LAD.geometry.type === "Polygon") {
            LAD.geometry.coordinates[0].forEach(coord => {
                if (!minLng || minLng > coord[0])
                    minLng = coord[0];
                if (!minLat || minLat > coord[1])
                    minLat = coord[1];
                if (!maxLng || maxLng < coord[0])
                    maxLng = coord[0];
                if (!maxLat || maxLat < coord[1])
                    maxLat = coord[1];
            });
        }
        else {
            LAD.geometry.coordinates.forEach(polygon => {
                polygon[0].forEach(coord => {
                    if (!minLng || minLng > coord[0])
                        minLng = coord[0];
                    if (!minLat || minLat > coord[1])
                        minLat = coord[1];
                    if (!maxLng || maxLng < coord[0])
                        maxLng = coord[0];
                    if (!maxLat || maxLat < coord[1])
                        maxLat = coord[1];
                });
            })
        }

        LAD.properties.minLng = minLng;
        LAD.properties.minLat = minLat;
        LAD.properties.maxLng = maxLng;
        LAD.properties.maxLat = maxLat;
    })

    return LADs;
}

const LADs = calculateZoom(LADData);

const countOutlets = (outlets) => {
    const outletsByLAD = {}

    LADs.features.forEach(LAD => {
        const outletsInLAD = outlets.filter(outlet => outlet.COVERAGE_LAD.includes(LAD.properties.LAD23CD));
        outletsByLAD[LAD.properties.LAD23CD] = outletsInLAD.length;
    });

    return outletsByLAD;
}

const Boundaries = ({ activeLAD, outlets }) => {
    let highlightLAD;

    const outletsByLAD = countOutlets(outlets);

    LADs.features.forEach(LAD => {
        if (activeLAD && activeLAD.LAD23CD === LAD.properties.LAD23CD)
            highlightLAD = LAD;

        if (outletsByLAD[LAD.properties.LAD23CD])
            LAD.properties.outlets = outletsByLAD[LAD.properties.LAD23CD];
        else
            LAD.properties.outlets = 0;
    });


    const dataLayer = {
        id: 'data',
        type: 'fill',
        paint: {
            'fill-color': {
                property: 'outlets',
                stops: [
                    [0, colours.WHITE],
                    [1, colours.GREY],
                    [10, colours.BLUE]
                ]
            },
            'fill-opacity': 0.5,
            "fill-outline-color": '#265089'
        }
    }

    const highlightLADStyle = {
        type: "line",
        paint: {
            "line-color": '#265089',
            "line-width": 4
        }
    };

    const highlightLADFeature = {
        "type": "FeatureCollection",
        "features": [highlightLAD]
    }

    // Source component only does a shallow compare to see if the data is different
    // so we change the name randomly and create a new object
    LADs.name += `${Math.random()}`;
    const LADsByOutlet = Object.assign({}, LADs);

    return <><Source type="geojson" data={LADsByOutlet}>
        <Layer {...dataLayer} />
    </Source>
        {highlightLAD && <Source type="geojson" data={highlightLADFeature}>
            <Layer {...highlightLADStyle} />
        </Source>}
    </>
}

export default Boundaries;